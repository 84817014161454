export default {
    namespaced: true,

    state() {
        return {
            
        };
    },

    actions: {
        test() {
            console.log('test')
        }
    },

    getters: {
        
    }
}